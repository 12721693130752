// src/routes/RoutePaths.ts
export enum RoutePaths {
    Home = '/',
    Suite = '/suite',
    InvoiceGenerator = '/invoice-generator',
    ChangeLog = '/changelog',
    Login = '/login',
    PricingPage = '/pricing',
    Checkout = '/checkout',
    BlogPost = '/blog/:slug',
    ListOfSameCategoryBlogPosts = '/blog/category/:categoryName',
    ForgotPassword = '/forgot-password',
    Register = '/register',
    Dashboard = '/dashboard',
    Settings = '/dashboard/account-settings',
    Billing = '/dashboard/billing',
    MyQueries = '/dashboard/my-queries',
    Categories = '/dashboard/categories',
    Category = '/dashboard/category/:id',
    CategoryEdit = '/dashboard/category/:id/edit',
    QueryCategoryAssignments = '/dashboard/query-category-assignments',
    Support = '/dashboard/support',
    Team = '/dashboard/team',
    RegistrationReceived = '/registration-received',
    ResetPasswordEmailSent = '/reset-password-email-sent',
    AccountConfirm = '/account/confirm',
    AccountReset = '/account/reset',
    Admin = '/admin', //done
    AdminDashboard = '/admin/dashboard', //done
    AdminUsers = '/admin/users',
    AdminUser = '/admin/user/:id',
    AdminUserEdit = '/admin/user/:id/edit',
    AdminUserCreate = '/admin/user/create',
    AdminUserDelete = '/admin/user/:id/delete',
    AdminRoles = '/admin/roles', //done
    AdminSubscriptionTiers = '/admin/subscription-tiers', //done
    AdminRole = '/admin/role/:id', //done
    AdminRoleEdit = '/admin/role/:id/edit',//done 
    AdminSubscriptionTierEdit = '/admin/subscription-tier/:id/edit',//done   
    AdminRoleUserAssignments = '/admin/role-user-assignments',
    AdminCategories = '/admin/categories',
    AdminCategory = '/admin/category/:id',
    AdminCategoryEdit = '/admin/category/:id/edit',
    AdminCategoryCreate = '/admin/category/create',
    AdminCategoryDelete = '/admin/category/:id/delete',
    AdminProducts = '/admin/products',
    AdminProduct = '/admin/product/:id',
    AdminProductEdit = '/admin/product/:id/edit',
    AdminProductCreate = '/admin/product/create',
    AdminProductDelete = '/admin/product/:id/delete',
    AdminOrders = '/admin/orders',
    AdminOrder = '/admin/order/:id',
    AdminOrderEdit = '/admin/order/:id/edit',
    AdminOrderCreate = '/admin/order/create',
    AdminOrderDelete = '/admin/order/:id/delete',
    AdminOrderItems = '/admin/order-items',
    AdminOrderItem = '/admin/order-item/:id',
    AdminOrderItemEdit = '/admin/order-item/:id/edit',
    AdminOrderItemCreate = '/admin/order-item/create',
    AdminOrderItemDelete = '/admin/order-item/:id/delete',
    AdminOrderStatuses = '/admin/order-statuses',
    AdminOrderStatus = '/admin/order-status/:id',
    AdminOrderStatusEdit = '/admin/order-status/:id/edit',
    AdminOrderStatusCreate = '/admin/order-status/create',
    AdminOrderStatusDelete = '/admin/order-status/:id/delete',
    AdminOrderHistories = '/admin/order-histories',
    AdminOrderHistory = '/admin/order-history/:id',
    AdminOrderHistoryEdit = '/admin/order-history/:id',
    DisplayEpRegister = '/display/:applicationNr',
    PowerDashboard = '/power-dashboard/:applicationNumber',//done 
    PublicDashboard = '/public-dashboard/:applicationNumber',//done 
    DynamicDashboard = '/dynamic-dashboard/:applicationNumber',//done 
    GuestDashboard = '/guest-dashboard/:applicationNumber',   //done 
    FamilyTreeSearchIframe = '/family-tree-search-iframe',
    EpoCalendarIframe = '/epo-calendar-iframe',
}