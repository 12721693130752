// src/htmlBlocks/ByTheProPlanProps.tsx
import React from "react";
import { Link } from 'react-router-dom';
import { RoutePaths } from '../routes/RoutePaths';
interface ByTheProPlanProps {
  proPlanPrice: number;
}

const ByTheProPlan: React.FC<ByTheProPlanProps> = ({ proPlanPrice }) => {
  return (
    <Link
      to={`${RoutePaths.Checkout}?price=${proPlanPrice}`}
      className="block w-full justify-center w-full px-6 py-2 mb-3 text-base font-medium text-center text-white bg-purple-600 rounded-lg hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 md:mr-5 md:mb-0">
      Buy the Pro plan
    </Link>
  );
};

export default ByTheProPlan;