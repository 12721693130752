// src/pages/Suite.tsx
import React from 'react';
import CenteredList from '../blog/components/CenteredList';
import useParallax from '../hooks/useParallax';
import EpoCalendar from '../suite_features/epo_calendar/components/EpoCalendar';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../routes/RoutePaths';

const Suite: React.FC = () => {
    useParallax('parallax-section');

    return (
        <div>
            <section className="py-8 px-4 bg-gray-50 dark:bg-black mx-auto sm:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-3xl text-center my-32">
                    <h1 className="mt-16 mb-4 font-normal text-gray-900 dark:text-white text-5xl lg:text-6xl xl:px-36 lg:mb-7">
                        Welcome to the Patalytica Suite
                    </h1>
                </div>
                <div className="mx-auto max-w-screen-md text-center">
                    <h2 className="mt-10 mb-8 tracking-tight font-semibold text-gray-900 dark:text-white text-2xl md:text-4xl lg:text-5xl">
                        <div className="w-full p-4">
                            <h2 className="mb-6 font-normal text-gray-900 dark:text-white text-4xl lg:text-5xl">
                                Powerful tools for patent analytics and law firm management.
                            </h2>
                            <br />

                            <Link to={RoutePaths.InvoiceGenerator}>
                                <span className="self-center whitespace-nowrap text-xl text-gray-900 font-semibold dark:text-white">
                                    Law firm invoice generator
                                </span>
                            </Link>
                        </div>
                    </h2>
                    <br />
                </div>
            </section>

            <section id="parallax-section" className="relative py-8 px-4 bg-parallax bg-white dark:bg-gray-800 mx-auto sm:py-16 lg:px-6 ">
                <EpoCalendar />
            </section>

            <section className="bg-white dark:bg-gray-900 lg:pt-60">
                <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center">
                        <h2 className="mb-4 text-4xl tracking-tight font-semibold text-gray-900 dark:text-white">
                            Our Blog
                        </h2>
                        <p className="mb-10 text-xl font-normal dark:text-gray-300">
                            Use cases, tutorials, and latest developments of Patalytica products.
                        </p>
                    </div>
                    <div className="mx-auto max-w-screen-sm divide-y divide-gray-200 dark:divide-gray-700">
                        <CenteredList />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Suite;
